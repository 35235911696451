import getQueryValue from '@invitato/helpers/dist/getQueryStringValue';
import useGuestInfo from './useGuestInfo';
import { useLocation } from '@reach/router';
import { ENABLE_PARTNER_MODE } from '@/constants/feature-flags';

/**
 * Custom hook to return parner
 * @return {string}
 */
function usePartner() {
  const { partner } = useGuestInfo();
  const location = useLocation();
  const partnerParam = getQueryValue(location, 'partner');

  if (!ENABLE_PARTNER_MODE) return 0;

  return Number(partner || partnerParam || 0);
}

export default usePartner;
